import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../services/admin/admin.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { IModifytempdoa } from '../../interfaces/admin/modifytempdoa';
import { CalAngularService } from '@cvx/cal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RemoveDialogComponent } from './remove-dialog/remove-dialog.component';
import { ISearchCriteria } from 'src/app/interfaces/search/searchcriteria';
import { TableSortService } from 'src/app/services/table-sort.services';
import { SearchService } from 'src/app/services/search/search.service';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { MatSort, Sort } from '@angular/material/sort';
import { IReferenceData } from 'src/app/interfaces/admin/referencedata';

import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-modifytempararydoa',
  templateUrl: './modifytempararydoa.component.html',
  styleUrl: './modifytempararydoa.component.css',
})
export class ModifytempararydoaComponent implements OnInit {
  title = 'Modify Temporary DOA';
  tableName = 'List of Delegations';
  public searchForm: FormGroup;
  showTable: boolean = false;
  dataSourceLoading: boolean = false;
  currentBU: string = '';
  businessUnit: string = '';
  private issorting = true;
  public searchCriteria: ISearchCriteria;
  public referenceData: IReferenceData;
  public dataSource: MatTableDataSource<IModifytempdoa>;
  public originalDataSource: MatTableDataSource<IModifytempdoa>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: string[] = [
    'startDate',
    'endDate',
    'dueTo',
    'toName',
    'from_Name',
    'createdBy',
    'created',
    'modify',
    'action',
  ];

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private authService: CalAngularService,
    private searchDOADataService: SearchService,
    private tableSortService: TableSortService,
    public dialog: MatDialog,
    private router: Router,
    private commonService: CommonService
  ) {
    this.dataSource = new MatTableDataSource<IModifytempdoa>();
  }

  ngOnInit(): void {
   
    this.businessUnit = this.commonService.getCurrentBusinessUnit();    
    this.searchForm = this.fb.group({
      status: ['All'],
      delegateCAI: ['', [Validators.email]],
      delegateName: [''],
      assignedReason: ['All'],
      delegatorCAI: ['', [Validators.email]],
      submittedByCAI: ['', [Validators.email]],
      department: [''],
    });
    this.showTable = this.dataSource.data.length === 0;
    //this.dataSource.sort = this.sort ;

    if (this.authService.isUserSignedIn()) {
      this.referenceData = this.commonService.getReferenceData();
      this.currentBU = this.commonService.getCurrentBusinessUnit();  
         if (this.currentBU == null) {
          this.currentBU = 'SOUTHERN AFRICA SBU';
        }      
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(event: Sort) {
    this.dataSource.data = this.tableSortService.sortDataSource(
      this.originalDataSource.data,
      event
    );
  }

  clearFilters() {
    this.searchForm.reset({
      status: 'All',
      delegateName: '',
      delegateCAI: '',
      department: '',
      assignedReason: 'All',
      delegatorCAI: '',
      submittedByCAI: '',
    });
    this.dataSource.data = [];
    this.showTable = true;
  }

  openRemoveDialog(item: any): void {
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      width: '800px',
      position: { top: '5%' },
      panelClass: 'custom-dialog-container',
      data: {
        message: `Are you sure you want to delete selected role permanently`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.removeFromUI(item);
      }
    });
  }

  removeFromUI(item: any): void {
    const index = this.dataSource.data.indexOf(item);
    if (index !== -1) {
      this.adminService
        .deletetempDOA(item.redelegationID, this.currentBU)
        .subscribe(
          (mydata: any) => {
            this.dataSource.data.splice(index, 1);
            this.dataSource._updateChangeSubscription();
            this.dialog.open(SuccessDialogComponent, {
              width: '500px',
              position: { top: '5%' },
              panelClass: 'custom-dialog-container',
              data: { title: 'Message', message: 'Removed successfully' },
            });
            this.router.navigate(['Home']);
          },
          (error) => {
            console.error('Error deleting item:', error);
            this.dialog.open(SuccessDialogComponent, {
              width: '500px',
              position: { top: '5%' },
              panelClass: 'custom-dialog-container',
              data: { title: 'Error', message: 'Failed to remove item' },
            });
          }
        );
    }
  }

  onSearch() {
    this.dataSourceLoading = true;
    this.showTable = false;
    this.searchCriteria = this.searchForm.value;
    this.searchCriteria.businessUnit = this.businessUnit;
    this.searchDOADataService
      .getSearchTempDOA(this.searchCriteria)
      .subscribe((data: any) => {
        if (data != null && data.length > 0) {
          this.showTable = false;
          this.dataSource = new MatTableDataSource(data);
          this.originalDataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
        } else {
          this.showTable = true;
          this.dataSource.data = [];
        }
        this.dataSourceLoading = false;
      });
  }

  applyFilter(filterValue: string, column: string) {
    let currentFilter = this.dataSource.filter ? JSON.parse(this.dataSource.filter) : {};
    currentFilter[column] = filterValue.toLowerCase();
    this.dataSource.filter = JSON.stringify(currentFilter);
    this.dataSource.filterPredicate = (data: IModifytempdoa, filter: string) => {
        const transformedFilter = JSON.parse(filter);
        return Object.keys(transformedFilter).every(key => 
            data[key].toLowerCase().includes(transformedFilter[key].toLowerCase())
        );
    };
    this.dataSource.filter = JSON.stringify(currentFilter);
  }

  openUpdateDialog(item: any): void {
    this.commonService.tempRedelegationId = item.redelegationID;
    this.commonService.isNewRequest = false;
    this.commonService.isUpdateRequest = true;
    this.router.navigate(['/Admin/EnterTemporaryDOA'], { state: { data: item } });
  }

  // viewPermanentDOA(item: any) : void {
  //   this.router.navigate(['/DOA/PermanentDOA'], { state: { data: item } });
  // }
  viewLatestPermanentDOA(row) {
    var param = {
      userEmail: row.from,
    };
    this.commonService.params = param;
    this.router.navigate(['/DOA/PermanentDOA']);
  }
}
