import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IHolidayList } from '../../../interfaces/admin/holidaylist';
import { AdminService } from '../../../services/admin/admin.service';
import { CommonService } from 'src/app/services/common/common.service';
import { CalAngularService } from '@cvx/cal-angular';

@Component({
  selector: 'app-add-update-holiday-dialog',
  templateUrl: './add-update-holiday-dialog.component.html',
  styleUrls: ['./add-update-holiday-dialog.component.css']
})
export class AddUpdateHolidayDialogComponent {
  businessUnit: string = '';
  emailAddress: string = '';
  isLoading = false;  
  errorMessage: string = '';  
 
  constructor(
    public dialogRef: MatDialogRef<AddUpdateHolidayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private holidayListService: AdminService,
    private snackBar: MatSnackBar,
    private commonService:CommonService,
    private authService: CalAngularService,
  ) {
   
    this.businessUnit = this.commonService.getCurrentBusinessUnit();    
    this.emailAddress = this.authService.cvxClaimsPrincipal.userName;

 
    if (!data.isEdit) {
      this.data.holiday = { date: null, description: '', businessUnit: this.businessUnit,emailAddress:this.emailAddress };
    } else {
      this.data.holiday.businessUnit = this.businessUnit;
      const year = new Date().getFullYear();
      this.data.holiday.date = new Date(year, this.data.holiday.month - 1, this.data.holiday.day);
    }
  } 
  onCancel(): void {
    this.dialogRef.close();
  } 
  onSave(): void {
    this.errorMessage = ''; 
    if (!this.data.holiday.date) {
      this.commonService.openModal('','Please select a holiday date.');
      return;
    }
    if (!this.data.holiday.description || this.data.holiday.description.trim() === '') {
      this.commonService.openModal('','Please enter a holiday description.');
      return;
    }   
    this.isLoading = true;  
    this.data.holiday.businessUnit = this.businessUnit;
    this.data.holiday.LoggedInUserEmail = this.emailAddress;
    const selectedDate = new Date(this.data.holiday.date);
    if (isNaN(selectedDate.getTime())) {
            this.commonService.openModal('','Invalid date selected.');
      this.isLoading = false;
      return;
    }
   
    this.data.holiday.day = selectedDate.getDate();  
    this.data.holiday.month = selectedDate.getMonth() + 1;  
   
    if (this.data.isEdit) {
      this.holidayListService.updateHoliday(this.data.holiday).subscribe({
        next: response => {
          this.isLoading = false;  
          this.commonService.openModal('Message','Holiday details updated successfully');
          this.dialogRef.close(response);
        },
        error: error => {
          this.isLoading = false;  
          console.error('Error updating holiday', error);
        }
      });
    } else {
      this.holidayListService.addHoliday(this.data.holiday).subscribe({
        next: response => {
          this.isLoading = false;  
          this.commonService.openModal('Message','New holiday details added successfully');
          this.dialogRef.close(response);
        },
        error: error => {
          this.isLoading = false;  
          console.error('Error adding holiday', error);
        }
      });
    }
  }
}