export const environment = {
  production: false,
  groupName: "--",
  BusinessUnit: "SOUTHERN AFRICA SBU",
  apiUrl: "https://doa-sasbu-api-test.azure.chevron.com/api",
  scope: "https://doa-sasbu-test.azure.chevron.com/user_impersonation",
  histroyReportUrl: "https://app.powerbi.com/reportEmbed?reportId=9ddbe351-5b18-4603-ac7d-87fe7383cb53&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  logReportUrl: "https://app.powerbi.com/reportEmbed?reportId=fbae99c4-2e0f-44b7-b0ae-61e3e08e8453&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  integrityReportUrl: "https://app.powerbi.com/reportEmbed?reportId=5756dc25-7250-4228-9fcc-bd1af78c7141&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  masterReportUrl: "https://app.powerbi.com/reportEmbed?reportId=75034be2-0399-47e4-a45e-65b6722cb702&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"



};
