import { Injectable } from '@angular/core';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string | null = null;

  constructor(private encryptionService: EncryptionService) {}

  setToken(token: string): void {
    this.token = token;
  }

  getToken(): string | null {
    return this.token;
  }

  getEmployeeRoles(): string[] {
    const storedData = localStorage.getItem('EmployeeInfo');
    if (storedData) {
      try {
        const decryptedData = this.encryptionService.decryptData(storedData);
        return decryptedData.roles || [];
      } catch (error) {
        console.error('Error decrypting data of Employee Roles:', error);
      }
    }

    return [];
  }
}
