import { Component } from '@angular/core';
import { ListOfPermanentDelegations } from 'src/app/interfaces/doa/listofpermanentdelegation';
import { ListOfTemporaryDelegations } from 'src/app/interfaces/doa/listoftemporarydelegation';
import { DelegationOfAuthorityService } from 'src/app/services/doa/delegationOfAuthority.service';
import { UserInformationComponent } from '../user-information/user-information.component';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'myprofile',
  templateUrl: 'myprofile.component.html',
  styleUrls: ['myprofile.component.css'],
  providers: [UserInformationComponent],
})
export class MyProfileComponent {
  constructor(
    private doaService: DelegationOfAuthorityService,
    private userInformationComponent: UserInformationComponent,
    private router: Router,
    private commonService: CommonService
  ) {}
  title = 'DOA Profile';
  subtitle = '';
  businessUnit: string;

  permanentDoaDisplayedColumns: string[] = [
    'serialNo',
    'grantorName',
    'grantorEmail',
    'effectiveDate',
  ];
  tempDoaDisplayedColumns: string[] = [
    'serialNo',
    'grantorName',
    'grantorEmail',
    'startDate',
    'endDate',
  ];
  permanentDoaDataSource: ListOfPermanentDelegations[];
  tempDoaDataSource: ListOfTemporaryDelegations[];

  ngOnInit(): void {
    let email = this.userInformationComponent.loggedUserEmail;
    this.businessUnit = this.commonService.getCurrentBusinessUnit();
    this.doaService
      .getDOAProfile(email, this.businessUnit)
      .subscribe((data) => {
        if (data && data.grantorsDetails) {
          this.permanentDoaDataSource = data.grantorsDetails;
        }
        if (data && data.temporaryDetails) {
          this.tempDoaDataSource = data.temporaryDetails;
        }
      });
  }

  redirectToPermanentDOA(entity) {
    var param = {
      requestId: 0,
      typeCode: 0,
      empEmail: entity.grantorEmail,
      action: 'View',
    };
    this.commonService.params = param;
    this.router.navigate(['/DOA/PermanentDOA']);
  }

  redirectToTemporaryDOA(redelegationId: number): void {
    this.commonService.tempRedelegationId = redelegationId;
    this.commonService.isNewRequest = false;
    this.commonService.isUpdateRequest = false;
    this.router.navigate(['/DOA/TemporaryDOA']);
  }
}
