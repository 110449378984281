// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .modal-header {
      background-color: #0b2d7;
      color: white;
      padding: 10px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .modal-content {
      padding: 20px;
      max-height: 300px; /* Set a maximum height */
      height: auto; /* Allow height to adjust based on content */
      overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
    }
    .modal-actions {
      padding: 10px;
      text-align: right;
    }
  `, "",{"version":3,"sources":["webpack://./src/app/doa-info-modal/doa-info-modal.component.ts"],"names":[],"mappings":";IACI;MACE,wBAAwB;MACxB,YAAY;MACZ,aAAa;MACb,2BAA2B;MAC3B,4BAA4B;IAC9B;IACA;MACE,aAAa;MACb,iBAAiB,EAAE,yBAAyB;MAC5C,YAAY,EAAE,4CAA4C;MAC1D,gBAAgB,EAAE,4DAA4D;IAChF;IACA;MACE,aAAa;MACb,iBAAiB;IACnB","sourcesContent":["\n    .modal-header {\n      background-color: #0b2d7;\n      color: white;\n      padding: 10px;\n      border-top-left-radius: 4px;\n      border-top-right-radius: 4px;\n    }\n    .modal-content {\n      padding: 20px;\n      max-height: 300px; /* Set a maximum height */\n      height: auto; /* Allow height to adjust based on content */\n      overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */\n    }\n    .modal-actions {\n      padding: 10px;\n      text-align: right;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
