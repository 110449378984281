import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private generateKeyAndIv(): {
    key: CryptoJS.lib.WordArray;
    iv: CryptoJS.lib.WordArray;
  } {
    const key = CryptoJS.lib.WordArray.random(16);
    const iv = CryptoJS.lib.WordArray.random(16);
    return { key, iv };
  }

  encryptData(data: any): string {
    const { key, iv } = this.generateKeyAndIv();
    const jsonString = JSON.stringify(data);
    const encrypted = CryptoJS.AES.encrypt(jsonString, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedData = {
      ciphertext: encrypted.toString(),
      key: key.toString(CryptoJS.enc.Base64),
      iv: iv.toString(CryptoJS.enc.Base64),
    };
    return JSON.stringify(encryptedData);
  }

  decryptData(data: string): any {
    const encryptedData = JSON.parse(data);
    const key = CryptoJS.enc.Base64.parse(encryptedData.key);
    const iv = CryptoJS.enc.Base64.parse(encryptedData.iv);
    const decrypted = CryptoJS.AES.decrypt(encryptedData.ciphertext, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }
}
